import { useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CustomizedTableCell = styled(TableCell)`
  text-align: center;
  padding: 4px;
  padding-left: 18px;
`;

interface DiffStyleProps {
  data: any;
}

function OldDiffStyle({ data }: DiffStyleProps) {
  return (
    <Box
      sx={{
        p: "5px",
        m: "5px",
        display: "inline-flex",
        color: "#e53935",
        fontWeight: "bold",
        textDecoration: "line-through",
      }}
    >
      {data}
    </Box>
  );
}

function NewDiffStyle({ data }: DiffStyleProps) {
  return (
    <Box
      sx={{
        p: "5px",
        m: "5px",
        display: "inline-flex",
        color: "#4ac74d",
        fontWeight: "bold",
      }}
    >
      {data}
    </Box>
  );
}

function DefaltDiffStyle({ data }: DiffStyleProps) {
  return (
    <Box
      sx={{
        p: "5px",
        m: "5px",
        display: "inline-flex",
      }}
    >
      {data}
    </Box>
  );
}

// Base on the last part of the Row ID to identify the Row style
export default function BasicTableWIP(props: any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 750, maxWidth: 1032 }}>
        <Table stickyHeader aria-label="confirm table">
          <TableHead>
            <TableRow>
              {Object.keys(props.data[0]["new"])
                .filter((key) => {
                  return key !== "id" && key !== "diff";
                })
                .map((key, index) => {
                  return (
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      {key}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                  <TableRow hover>
                    {Object.keys(row["new"]).map((key, index) => {
                      if (row.diff.includes(key)) {
                        return (
                          <CustomizedTableCell key={row.index}>
                            <OldDiffStyle
                              data={row["old"][key as keyof typeof row["old"]]}
                            />
                            &#8594;
                            <NewDiffStyle
                              data={row["new"][key as keyof typeof row["new"]]}
                            />
                          </CustomizedTableCell>
                        );
                      } else {
                        return (
                          <CustomizedTableCell key={row.index}>
                            <DefaltDiffStyle
                              data={row["new"][key as keyof typeof row["new"]]}
                            />
                          </CustomizedTableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
