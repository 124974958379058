export const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY as string,
    clientId: process.env.REACT_APP_WEBSEC_CLIENT_ID as string,
    redirectUri: "/",
    scopes: ["openid", "profile", "email"],
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};
