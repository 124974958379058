import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CompareAccordion from "./CompareAccordion";
import { ComparePlanResponse, ReviewAction } from "../../types";
import { COMPARE_PLAN } from "../../graphql/queries";
import { LoadingButton } from "@mui/lab";
import { SUBMIT_PLAN_MUTATION } from "../../graphql/mutations";

interface CompareStepProps {
  planId: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

interface CompareSectionProps {
  complete: boolean;
  loading: boolean;
  comparePlanResponse: ComparePlanResponse;
  handleReset: () => void;
  handleMerge: (reason: string) => void;
  uploading: boolean;
}

function isComparePlanResponse(responses: ComparePlanResponse): boolean {
  return responses.comparePlan.length > 0;
}

function CompareSection({
  complete,
  loading,
  comparePlanResponse,
  handleReset,
  handleMerge,
  uploading,
}: CompareSectionProps) {
  if (complete) {
    return CompleteSection(handleReset);
  } else if (loading) {
    return LoadingSection();
  } else if (!isComparePlanResponse(comparePlanResponse)) {
    return ErrorSection(comparePlanResponse, handleReset);
  } else {
    return SuccessSection(
      complete,
      comparePlanResponse,
      handleMerge,
      uploading
    );
  }
}

function CompleteSection(handleReset: () => void) {
  return (
    <Grid container item spacing={1.5} direction="column">
      <Grid>
        <Alert severity="success">
          <AlertTitle>Thanks for submitting!</AlertTitle>
        </Alert>
      </Grid>
      <Grid container item justifyContent="flex-end">
        <Button onClick={handleReset} variant="contained" color="success">
          Start Over
        </Button>
      </Grid>
    </Grid>
  );
}

// Loading ComparePlan API responses from BackEnd
function LoadingSection() {
  return (
    <Stack
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <Typography>Comparing...</Typography>
    </Stack>
  );
}

// Display no changes find from ComparePlan API
function ErrorSection(
  comparePlanResponse: ComparePlanResponse,
  handleReset: () => void
) {
  return (
    <Grid container item spacing={1.5} direction="column">
      <Grid item sx={{ mb: 1.5 }}>
        <Alert severity="warning">
          <AlertTitle>Looks like there is nothing to change!</AlertTitle>
        </Alert>
      </Grid>
      <Grid item>
        <CompareAccordion data={comparePlanResponse} />
      </Grid>
      <Grid container item justifyContent="flex-end">
        <Button onClick={handleReset} variant="contained" color="error">
          Start Over
        </Button>
      </Grid>
    </Grid>
  );
}

// Display all the changes based on Main
function SuccessSection(
  complete: boolean,
  comparePlanResponse: ComparePlanResponse,
  handleMerge: (reason: string) => void,
  uploading: boolean
) {
  const [text, setText] = useState<string>("");

  return (
    <Grid container item spacing={1.5} direction="column">
      <Grid item sx={{ mb: 1.5 }}>
        <Alert severity="success">
          <AlertTitle>
            Thanks! Confirm your changes below and provide a justification.
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid item>
        <CompareAccordion data={comparePlanResponse} />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="comments"
          onChange={(e) => setText(e.target.value)}
        />
      </Grid>
      <Grid container item justifyContent="flex-end">
        <LoadingButton
          loading={uploading}
          type="submit"
          variant="contained"
          disabled={uploading || text.length < 1}
          onClick={() => handleMerge(text)}
        >
          Confirm and Send
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

function CompareStep({ planId, setActiveStep }: CompareStepProps) {
  const [complete, setComplete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [submitPlan, { data: submitPlanPayload, error: submitPlanError }] =
    useMutation(SUBMIT_PLAN_MUTATION);
  const {
    data: comparePlanResponse,
    loading,
    error: comparePlanError,
  } = useQuery<ComparePlanResponse>(COMPARE_PLAN, {
    variables: {
      Id: planId,
    },
  });

  async function handleMerge(reason: string) {
    setUploading(true);
    const submitPlanPromise = await submitPlan({
      variables: {
        id: planId,
        action: ReviewAction.REVIEW,
        reason: reason,
      },
    });
    setComplete(true);
  }

  function handleReset() {
    // There will be more code about use Muatation calling CancelPlan
    // This just leave Helper Function for the future, so I don't need to can back to create this helper function again
    setActiveStep(0);
  }

  if (comparePlanError) {
    return <Alert severity="error">{comparePlanError.message}</Alert>;
  }

  if (submitPlanError) {
    return <Alert severity="error">{submitPlanError.message}</Alert>;
  }

  // Requesting ComparePlan Api
  // When Responses have 'comparePlan' data include. Load the ControlledAccordions component with the comparePlanResponse
  return (
    <CompareSection
      complete={complete}
      loading={loading}
      comparePlanResponse={comparePlanResponse ?? { comparePlan: [] }}
      handleReset={handleReset}
      handleMerge={handleMerge}
      uploading={uploading}
    />
  );
}

export default CompareStep;
