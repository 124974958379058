import { Alert } from "@mui/material";
import { FileRejection } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCircleXmark,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";

const invalidFileIcon = <FontAwesomeIcon icon={faFileCircleXmark} />;
const validFileIcon = <FontAwesomeIcon icon={faFileCsv} />;

interface FileStatusProps {
  acceptedFiles: File[];
  fileRejections: FileRejection[];
}

function isFileArrayEmpty(files: File[] | FileRejection[]) {
  return files.length < 1;
}

function FileStatus({ acceptedFiles, fileRejections }: FileStatusProps) {
  return (
    <>
      {!isFileArrayEmpty(acceptedFiles) ? (
        <Alert icon={validFileIcon} severity="success">
          {acceptedFiles[0].name}
        </Alert>
      ) : !isFileArrayEmpty(fileRejections) ? (
        <Alert icon={invalidFileIcon} severity="error">
          {fileRejections[0].file.name} is not supported. Please upload a .CSV
          file.
        </Alert>
      ) : null}
    </>
  );
}
export default FileStatus;
