import { Alert, AlertTitle, Button, Grid } from "@mui/material";

interface SuccessSectionProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function SuccessSection({ setActiveStep }: SuccessSectionProps) {
  return (
    <Grid container item direction="column" spacing={1.5}>
      <Grid item>
        <Alert severity="success">
          <AlertTitle>Your data looks good!</AlertTitle>
          Click Next to confirm
        </Alert>
      </Grid>
      <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={() => setActiveStep(2)}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
}

export default SuccessSection;
