import { useState, useEffect } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Button, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { MAIN_PLAN } from "../../../graphql/queries";
import { MainPlanResponse, PlanType } from "../../../types";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";

import { planInfoConfigs } from "./PlanInfoConfig";
import RequestMainPlan from "./RequestMainPlan";

interface PlanInfoProps {
  planType: string;
}

interface PlanInfoTemplate {
  description: string;
  fields: string[];
  link: string;
  planTitle: string;
}

interface mainPlanResponseProps {
  planType: string;
}

const infoItems =
  "Acceptable Gear Names: DCAM, DCAM 2, DCAM 2 Spare, DCAM Spare, E6000, E6000r, RSM, RSM 2, UCAM, UCAM 2, UCAM 2 Spare, UCAM Spare, cBR - 8, cBR - 8 Docsis Card Gemini 2 / Leoben 3, DAAS Switch, cBR - 8 Docsis Card Gemini 2 / Leoben 3 Spare, DAAS Switch(Shelf), REEF Line - card 2X4(Active), REEF Line - card 2X4(Spare), System XI, REEF Shelf Chassis, Control POD V3.0, HAGG Switch, MAGG, PPOD V2.5, PPOD V3.0, XMF - R, Video Pod (Gen 3.0), 5171 Muxponder, CFP2-DCO Module";
function QuestionToolTip() {
  return (
    <Tooltip title={infoItems} placement="right">
      <IconButton>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
}

function SuccessSection({ planType }: mainPlanResponseProps) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
  }, [planType]);

  function changeState() {
    setShow(true);
  }
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          size="small"
          onClick={changeState}
        >
          Request {PlanType[planType as keyof typeof PlanType]} data
        </Button>
      </Grid>
      <Grid item>{show && <RequestMainPlan planType={planType} />}</Grid>
    </Grid>
  );
}

function PlanInfo({ planType }: PlanInfoProps) {
  const selectedPlan: Partial<PlanInfoTemplate> | undefined =
    planInfoConfigs.get(planType);

  return (
    <Card sx={{ p: 2 }}>
      <CardContent>
        <SuccessSection planType={planType} />
        {selectedPlan && Object.keys(selectedPlan).length > 0 ? (
          <>
            <Typography variant="h6">{selectedPlan.description}</Typography>
            <Typography sx={{ mt: 2 }}>
              <strong>{selectedPlan.planTitle}</strong> accepts the following
              headers and values:
              {planType == "POR" ? <QuestionToolTip /> : null}
            </Typography>
            <ul>
              {selectedPlan.fields?.map((field) => (
                <li key={field}>
                  <Typography sx={{ color: "grey.800" }}>{field}</Typography>
                </li>
              ))}
            </ul>
            <Link href={selectedPlan.link} target="_blank">
              Download submission template
              <LaunchIcon fontSize="small" />
            </Link>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default PlanInfo;
