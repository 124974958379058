import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { Alert, Box, Button, Grid, SelectChangeEvent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDropzone } from "react-dropzone";
import DefaultPrompt from "./DefaultPrompt";
import FileStatus from "./FileStatus";
import PlanInfo from "./PlanInfo/PlanInfo";
import SelectMenu from "./SelectMenu";
import {
  CREATE_PLAN_MUTATION,
  UPLOAD_PLAN_MUTATION,
} from "../../graphql/mutations";
import { toBase64 } from "../../utils/encoding";

interface UploadStepProps {
  file: File[];
  planType: string;
  setFile: React.Dispatch<React.SetStateAction<File[]>>;
  setPlanId: React.Dispatch<React.SetStateAction<string>>;
  setPlanType: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function UploadStep({
  file,
  planType,
  setActiveStep,
  setFile,
  setPlanId,
  setPlanType,
}: UploadStepProps) {
  const [loading, setLoading] = useState(false);
  const [createPlan, { data: createPlanPayload, error: createPlanError }] =
    useMutation(CREATE_PLAN_MUTATION);
  const [uploadPlan, { data: uploadPlanPayload, error: uploadPlanError }] =
    useMutation(UPLOAD_PLAN_MUTATION);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
  });

  function handleChange(event: SelectChangeEvent): void {
    setPlanType(event.target.value as string);
  }

  function isFormValid(): boolean {
    return planType.length > 0 && file.length > 0 && !uploadPlanPayload;
  }

  function isStepValid(): boolean {
    return uploadPlanPayload === undefined || loading;
  }

  const createAndUpload = async () => {
    setLoading(true);

    try {
      const createPlanPromise = await createPlan({
        variables: {
          type: planType,
        },
      });

      const encodedFileContent = await toBase64(file[0]);

      await uploadPlan({
        variables: {
          id: createPlanPromise.data.createPlan.id,
          content: encodedFileContent,
        },
      });

      setPlanId(createPlanPromise.data.createPlan.id);
      setLoading(false);
    } catch {}
  };

  if (createPlanError?.message) {
    return <Alert severity="error">{createPlanError.message}</Alert>;
  }

  if (uploadPlanError) {
    return <Alert severity="error">{uploadPlanError.message}</Alert>;
  }

  // TODO: Drop Box hiiden style move to main css
  // https://github.comcast.com/meta/caam-web-public/pull/33/files#r1540276
  return (
    <Grid container rowSpacing={3} direction="column">
      <Grid item>
        <SelectMenu planType={planType} handleChange={handleChange} />
      </Grid>
      <Grid item>
        {planType.length > 0 ? <PlanInfo planType={planType} /> : null}
      </Grid>
      <Grid item style={planType ? {} : { display: "none" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "grey.500",
            borderStyle: "dashed",
            backgroundColor: "grey.50",
            color: "grey.500",
            outline: "none",
            transition: "border .24s ease-in-out",
            cursor: "pointer",
            minHeight: 225,
            ...(isDragActive && {
              backgroundColor: "primary.50",
              borderColor: "primary.light",
              color: "primary.light",
            }),
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <DefaultPrompt />
        </Box>
      </Grid>
      <Grid item>
        <FileStatus
          acceptedFiles={acceptedFiles}
          fileRejections={fileRejections}
        />
      </Grid>
      <Grid
        container
        item
        direction="row"
        justifyContent={"space-between"}
        spacing={1}
      >
        <Grid item>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            disabled={!isFormValid()}
            onClick={createAndUpload}
          >
            Upload
          </LoadingButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={isStepValid()}
            onClick={() => setActiveStep(1)}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UploadStep;
