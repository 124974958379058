import { CircularProgress, Grid, Typography } from "@mui/material";

function LoadingSection() {
  return (
    <Grid
      container
      spacing={2}
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>Validating...</Typography>
      </Grid>
    </Grid>
  );
}

export default LoadingSection;
