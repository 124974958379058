import { PlanType } from "../../types";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface SelectMenuProps {
  planType: string;
  handleChange: any;
}

function SelectMenu({ planType, handleChange }: SelectMenuProps) {
  return (
    <FormControl fullWidth required={true} size="small">
      <InputLabel id="plan-type">Choose a plan type</InputLabel>
      <Select
        id="plan-type-select"
        labelId="plan-type"
        label="Choose a plan type"
        value={planType}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {(Object.keys(PlanType) as Array<keyof typeof PlanType>).map((key) => {
          return (
            <MenuItem key={key} value={key}>
              {PlanType[key]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SelectMenu;
