import { Alert, AlertTitle, Button, Grid, Typography } from "@mui/material";
import { PlanValidationError } from "../../types";
import ErrorRow from "./ErrorRow";

interface ErrorSectionProps {
  errors: PlanValidationError[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function ErrorSection({ errors, setActiveStep }: ErrorSectionProps) {
  return (
    <>
      <Grid container direction="column" rowSpacing={1.5}>
        <Grid item sx={{ mb: 1.5 }}>
          <Alert severity="warning">
            <AlertTitle>Oops!</AlertTitle>
            We found some data errors.
          </Alert>
        </Grid>
        <Grid
          container
          direction="row"
          rowSpacing={1.5}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography variant="h5">Validation Errors</Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={() => setActiveStep(0)}
            >
              Start Over
            </Button>
          </Grid>
        </Grid>
        {errors.map((error) => {
          return (
            <Grid item key={error.row}>
              <ErrorRow error={error} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default ErrorSection;
