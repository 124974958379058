import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicTableWIP from "../DataTable/BasicTableWIP";
import { DataBuilder } from "../DataTable/DataBuilder";
import { ComparePlanResponse } from "../../types";

interface ControlledAccordionsProps {
  data: ComparePlanResponse;
}

enum ComparePanel {
  NewRecords,
  ChangedRecords,
  Default,
}

export default function CompareAccordion({ data }: ControlledAccordionsProps) {
  const [expanded, setExpanded] = React.useState<ComparePanel>(
    ComparePanel.Default
  );
  const table = new DataBuilder();

  table.setRows(data);

  function handleChange(panel: ComparePanel) {
    return (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : ComparePanel.Default);
    };
  }

  return (
    <div>
      <Accordion
        expanded={expanded === ComparePanel.NewRecords}
        onChange={handleChange(ComparePanel.NewRecords)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
            New Records
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {table.NewRows.length > 0 ? (
            <BasicTableWIP data={table.NewRows} />
          ) : (
            <Typography>No new records have been uploaded.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === ComparePanel.ChangedRecords}
        onChange={handleChange(ComparePanel.ChangedRecords)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
            Changed Records
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {table.ModifyRows.length > 0 ? (
            <BasicTableWIP data={table.ModifyRows} />
          ) : (
            <Typography>No new records have been uploaded.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
