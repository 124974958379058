import { Box, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

function DefaultPrompt() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <UploadFileIcon />
      <Typography variant="h6">
        Drag and drop a file here, or click to select files
      </Typography>
      <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
        (Only .csv files will be accepted)
      </Typography>
    </Box>
  );
}

export default DefaultPrompt;
