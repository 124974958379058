import { gql } from "@apollo/client";

export const CREATE_PLAN_MUTATION = gql`
  mutation CreatePlan($type: PlanType!) {
    createPlan(
      input: { type: $type, file: { mimeType: CSV, encoding: BASE64 } }
    ) {
      id
      type
    }
  }
`;

export const UPLOAD_PLAN_MUTATION = gql`
  mutation UploadPlan($id: ID!, $content: String!) {
    uploadPlan(input: { id: $id, content: $content }) {
      id
    }
  }
`;

export const SUBMIT_PLAN_MUTATION = gql`
  mutation SubmitPlan($id: ID!, $action: ReviewAction!, $reason: String!) {
    submitPlan(input: { id: $id, action: $action, reason: $reason }) {
      file {
        mimeType
        url
      }
      review {
        action
        reason
        reviewer {
          email
          lastName
          firstName
        }
        submitter {
          email
          lastName
          firstName
        }
      }
    }
  }
`;
