import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { Button } from "@mui/material";

function signOutClickHandler(instance: IPublicClientApplication) {
  const logoutRequest = {
    account: instance.getActiveAccount(),
  };
  instance.logoutRedirect(logoutRequest);
}

function SignOutButton() {
  const { instance } = useMsal();

  return (
    <Button
      color="inherit"
      size="small"
      variant="outlined"
      onClick={() => signOutClickHandler(instance)}
    >
      Sign Out
    </Button>
  );
}

export default SignOutButton;
