import SignOutButton from "./SignOutButton";

import {
  AppBar as NavBar,
  Box,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";

function AppBar() {
  const { accounts } = useMsal();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar position="relative">
        <Toolbar>
          <Box sx={{ flexGrow: 1, marginBottom: 1 }}>
            <Typography variant="h6" component="div">
              CAAM Planner
            </Typography>
            <Typography variant="subtitle2" component="div">
              Access Network Planning Data Hub
            </Typography>
          </Box>
          <Typography padding={1}>{accounts[0].name}</Typography>
          <Divider
            sx={{ borderRightWidth: 1, background: "#C2C8D1", margin: 2 }}
            flexItem
            orientation="vertical"
            variant="middle"
          />
          <SignOutButton />
        </Toolbar>
      </NavBar>
    </Box>
  );
}

export default AppBar;
