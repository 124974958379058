import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Step,
  Stepper,
  StepLabel,
} from "@mui/material";
import UploadStep from "./UploadStep/UploadStep";
import CompareStep from "./CompareStep/CompareStep";
import ValidateStep from "./ValidateStep/ValidateStep";

function PlanCreationStepper() {
  // TODO: Look in to replace 1 or all 3 of these states with query parameters
  const [file, setFile] = useState<File[]>([]);
  const [planType, setPlanType] = useState<string>("");
  const [planId, setPlanId] = useState<string>("");

  enum Steps {
    Upload = "UPLOAD",
    Validate = "VALIDATE",
    Confirm = "CONFIRM",
  }

  const [activeStep, setActiveStep] = useState(0);

  function renderStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <UploadStep
            file={file}
            planType={planType}
            setActiveStep={setActiveStep}
            setFile={setFile}
            setPlanId={setPlanId}
            setPlanType={setPlanType}
          />
        );
      case 1:
        return <ValidateStep planId={planId} setActiveStep={setActiveStep} />;
      case 2:
        return <CompareStep planId={planId} setActiveStep={setActiveStep} />;
    }
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        padding: 5,
        marginTop: 3,
      }}
    >
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Stepper sx={{ mb: 5 }} activeStep={activeStep}>
            {(Object.keys(Steps) as Array<keyof typeof Steps>).map((label) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === Object.keys(Steps).length ? (
            // TODO: Add final step component or remove this conditional
            <h1>End page</h1>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 2,
                  mb: 5,
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} />
              </Box>
              <Grid container rowSpacing={3} direction="column">
                {renderStepContent(activeStep)}
              </Grid>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default PlanCreationStepper;
