import {
  createHttpLink,
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { GRAPHQL_URI } from "../constants/endpoints";
import { msalInstance } from "..";

async function getIdToken(msalInstance: PublicClientApplication) {
  const account: AccountInfo = await msalInstance.getAllAccounts()[0];
  const tokenRequest = {
    account,
    scopes: [],
  };

  if (account) {
    try {
      const authResponse: AuthenticationResult =
        await msalInstance.acquireTokenSilent(tokenRequest);
      return authResponse.idToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenPopup(tokenRequest);
      }
    }
  }

  return msalInstance.logoutRedirect(tokenRequest);
}

const httpLink: ApolloLink = createHttpLink({
  uri: GRAPHQL_URI,
});

const authLink: ApolloLink = setContext(
  async (_operation: GraphQLRequest, { headers }) => {
    const token: string | void | AuthenticationResult = await getIdToken(
      msalInstance
    );
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
