import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import AppBar from "./components/AppBar/AppBar";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Review from "./pages/Review";

import { Container } from "@mui/system";

import { ErrorBoundary } from "react-error-boundary";

function App() {
  return (
    <div>
      <AppBar />
      <Container maxWidth="lg">
        <ErrorBoundary fallback={<ErrorPage />}>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/review" element={<Review />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </Container>
    </div >
  );
}

export default App;
