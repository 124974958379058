import { gql } from "@apollo/client";

export const MAIN_PLAN = gql`
  query MainPlan($type: ReportType!) {
    mainPlan(type: $type)
  }
`;

export const PLAN = gql`
  query Plan($id: ID!) {
    plan(id: $id) {
      id
      type
      status
      review {
        reason
        submitter {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const SEARCH_REPORTS = gql`
  query searchReports($filter: KeyValueFilter) {
    searchReports(filter: $filter) {
      results {
        field
        value
      }
    }
  }
`;

export const VALIDATE_PLAN = gql`
  query validatePlan($id: ID!) {
    validatePlan(id: $id) {
      errors {
        field
        message
        row
      }
    }
  }
`;

export const COMPARE_PLAN = gql`
  query comparePlan($Id: ID!) {
    comparePlan(id: $Id) {
      diff
      diffType
      id
      new {
        field
        value
      }
      old {
        field
        value
      }
    }
  }
`;

export const USER_RECORDS = gql`
  query userRecords($Id: ID!) {
    userRecords(id: $Id)
  }
`;
