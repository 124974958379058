import LaunchIcon from "@mui/icons-material/Launch";
import { useQuery } from "@apollo/client";
import { Alert, Link, Skeleton } from "@mui/material";

import { USER_RECORDS } from "../../../graphql/queries";
import { userRecordsResponse } from "../../../types";

interface RequestUserRecordsProps {
  planId: string
}

function RequestUserRecords({ planId }: RequestUserRecordsProps) {
  const {
    data,
    loading,
    error,
  } = useQuery<userRecordsResponse>(USER_RECORDS, {
    variables: {
      Id: planId,
    },
  });

  if (loading) {
    return <Skeleton variant="text" />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Link href={data?.userRecords}>
      Download data
      <LaunchIcon fontSize="small" />
    </Link>
  )
}

export default RequestUserRecords;
