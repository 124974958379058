import { PlanType } from "../../../types";

export const planInfoConfigs = new Map<string, object>([
  [
    "DIGITAL_READINESS",
    {
      description:
        "Provide details on when hubs will be fully ready for digital deployments.",
      fields: [
        "dnsName: (e.g. 140mkt.pa)",
        "hubCode: (e.g. PAYO - expecting a string with four upper-case alphanumeric characters)",
        "digitalReadyMonth: (Excepting integer in range 1 to 12)",
        "digitalReadyYear: (Excepting a YYYY value among [2023, 2024, 2025, 2026])",
      ],
      link: "https://comcastcorp.sharepoint.com/:x:/s/CapacityAccessArchitectureModeling/EfMW0I-tqu1IkAKcDmaAWX8Bnx6HE6QtVBCNbmxntV0dKg?e=UCE4hU&download=1",
      planTitle: PlanType.DIGITAL_READINESS,
    },
  ],
  [
    "GENESIS_HHP",
    {
      description:
        "Provide Hub-specific targets of households passed for Project Genesis.",
      fields: [
        "dnsName: (e.g. 140mkt.pa)",
        "hubCode: (e.g. PAYO - expecting a string with four upper-case alphanumeric characters)",
        "targetHHP: (Excepting integer >= 0)",
        "targetYear: (Excepting a YYYY value among [2023, 2024, 2025, 2026])",
      ],
      link: "https://comcastcorp.sharepoint.com/:x:/s/CapacityAccessArchitectureModeling/EWnHCHPwcg9Ks4fjiVfv0LUBqLL42SQEdB8qGjOCtVKODw?e=0KzdG0&download=1",
      planTitle: PlanType.GENESIS_HHP,
    },
  ],
  [
    "SITE_TIER",
    {
      description: "Provide hub site tiering change information by year.",
      fields: [
        "dnsName: (e.g. 140mkt.pa)",
        "hubCode: (e.g. PAYO - expecting a string with four upper-case alphanumeric characters)",
        "year: (Expecting YYYY value among [2023, 2024, 2025, 2026])",
        "tier: (Expecting one of 1, 2A, 2B, 3 or 3X, FDX)",
      ],
      link: "https://comcastcorp.sharepoint.com/:x:/s/CapacityAccessArchitectureModeling/EcxfO8zFkb9MpyqzNm7xc9oBPfAGo4yYlC7Gh-pqN9afyw?e=aa3l4n&download=1",
      planTitle: PlanType.SITE_TIER,
    },
  ],
  [
    "POR",
    {
      description: "Provide POR details by year",
      fields: [
        "year: (Expecting YYYY)",
        "dnsName: (e.g. 140mkt.pa)",
        "hubCode: (e.g. PAYO - expecting a string with four upper-case alphanumeric characters)",
        "gearName : (See list of allowed gears in submission template e.g DCAM - expecting a string)",
        "equipmentType : (Expecting either Component or Device)",
        "porType: (Expecting either allocation or decommission)",
        "porQty: (Excepting an integer value)",
      ],
      link: "https://comcastcorp.sharepoint.com/:x:/s/CapacityAccessArchitectureModeling/EeX__QQLJHJBl1-eAqHlCPMBlJf5bqu1s0Sz5SydDH6oBg?e=O4qQxU&download=1",
      planTitle: PlanType.POR,
    },
  ],
  [
    "FDX_HHP",
    {
      description: "Provide Hub-specific targets of households passed for FDX",
      fields: [
        "dnsName: (e.g. 140mkt.pa)",
        "hubCode: (e.g. PAYO - expecting a string with four upper-case alphanumeric characters)",
        "target1StepFdxHhp (analog): (Expecting integer >= 0)",
        "target2StepFdxHhp (digital): (Expecting integer >= 0)",
        "targetYear: (Excepting a YYYY value)",
      ],
      link: "https://comcastcorp.sharepoint.com/:x:/s/CapacityAccessArchitectureModeling/ER-CIZnHCURDvnzOVzGlaLYBJrB4L6198I3dXgbKD_zwbg?e=PgL9w4&download=1",
      planTitle: PlanType.FDX_HHP,
    },
  ],
]);
