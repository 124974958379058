import LaunchIcon from "@mui/icons-material/Launch";
import {
  Alert,
  Card,
  CardContent,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useQuery } from "@apollo/client";
import { MAIN_PLAN } from "../../../graphql/queries";
import { MainPlanResponse, PlanType } from "../../../types";

interface mainPlanResponseProps {
  planType: string;
}

interface PlanInfoProps {
  planType: string;
}

function RequestMainPlan({ planType }: PlanInfoProps) {
  const {
    data: mainPlanResponse,
    loading,
    error,
  } = useQuery<MainPlanResponse>(MAIN_PLAN, {
    variables: {
      type: planType,
    },
  });

  const onDownload = (mainPlanResponse: any) => {
    const link = document.createElement("a");
    link.href = mainPlanResponse?.mainPlan;
    link.click();
  };
  if (loading) {
    return <Skeleton variant="text" />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Link href={mainPlanResponse?.mainPlan}>
      Download data
      <LaunchIcon fontSize="small" />
    </Link>
  );
}

export default RequestMainPlan;
