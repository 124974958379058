import { Alert } from "@mui/material";
import { PlanValidationError } from "../../types";

interface ErrorRowProps {
  error: PlanValidationError;
}

function ErrorRow({ error }: ErrorRowProps) {
  return (
    <Alert key={error.row} severity="error">
      {error.message} in row {error.row}
    </Alert>
  );
}

export default ErrorRow;
