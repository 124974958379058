import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CompareAccordion from "../components/CompareStep/CompareAccordion";
import { COMPARE_PLAN, PLAN } from "../graphql/queries";
import { SUBMIT_PLAN_MUTATION } from "../graphql/mutations";
import {
  ComparePlanResponse,
  PlanResponse,
  PlanType,
  ReviewAction,
} from "../types";
import { LoadingButton } from "@mui/lab";

import RequestUserRecords from "../components/UploadStep/PlanInfo/RequestUserRecords";

interface CompareSectionProps {
  complete: boolean;
  comparePlanResponse: ComparePlanResponse;
  submitPlanLoadingState: boolean;
  planId: string;
  planResponse: PlanResponse;
  handleApprove: () => void;
  handleDecline: (reason: string) => void;
  handleReset: () => void;
}

function isPlanRunning(responses: PlanResponse): boolean {
  return responses.plan.status === "RUNNING";
}

function CompareSection({
  complete,
  comparePlanResponse,
  planResponse,
  submitPlanLoadingState,
  handleApprove,
  handleDecline,
  handleReset,
}: CompareSectionProps) {
  if (complete) {
    return CompleteSection(handleReset);
  } else if (!isPlanRunning(planResponse)) {
    return ErrorSection(handleReset);
  } else {
    return SuccessSection(
      comparePlanResponse,
      planResponse,
      submitPlanLoadingState,
      handleApprove,
      handleDecline
    );
  }
}

function CompleteSection(handleReset: () => void) {
  return (
    <>
      <Typography sx={{ mt: 2, mb: 1 }}>
        All steps completed - you&apos;re finished
      </Typography>
      <Grid
        container
        item
        direction="row"
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button onClick={handleReset}>Upload Another Plan Update</Button>
        </Grid>
      </Grid>
    </>
  );
}

// Loading ComparePlan API responses from BackEnd
function LoadingSection() {
  return (
    <Stack
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
      <Typography>Comparing file with Main plan...</Typography>
    </Stack>
  );
}

// Display no changes find from ComparePlan API
function ErrorSection(handleReset: () => void) {
  return (
    <>
      <Typography>
        This upload doesn&apos;t have any differences with the Main Plan
      </Typography>
      <Grid
        container
        item
        direction="row"
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button onClick={handleReset}>Upload Another Plan Update</Button>
        </Grid>
      </Grid>
    </>
  );
}

interface PlanMetaDataProps {
  planResponse: PlanResponse;
}

function PlanMetaData({ planResponse }: PlanMetaDataProps) {
  return (
    <>
      <Typography variant="body1">
        Plan Type: {PlanType[planResponse?.plan.type as keyof typeof PlanType]}
      </Typography>
      <Typography variant="body1">
        Submitter: {planResponse?.plan.review.submitter.firstName}{" "}
        {planResponse?.plan.review.submitter.lastName}
      </Typography>
      <Typography variant="body1">
        Justification: {planResponse?.plan.review.reason}
      </Typography>
    </>
  );
}

// Display all the changes based on Main
function SuccessSection(
  comparePlanResponse: ComparePlanResponse,
  planResponse: PlanResponse,
  loading: boolean,
  handleApprove: () => void,
  handleDecline: (reason: string) => void
) {
  const [show, setShow] = useState(false);
  const [text, setText] = useState<string>("");

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4"> Review Plan</Typography>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={() => setShow(true)}
          >
            Request file
          </Button>
        </Grid>
        <Grid item>
          {show && <RequestUserRecords planId={planResponse?.plan.id} />}
        </Grid>
        <Grid item>
          <PlanMetaData planResponse={planResponse} />
        </Grid>
        <Grid item>
          <CompareAccordion data={comparePlanResponse} />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Comments"
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                color="error"
                disabled={text.length < 1}
                onClick={() => handleDecline(text)}
              >
                Decline
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                color="success"
                disabled={loading}
                onClick={handleApprove}
              >
                Approve & Merge
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function Review() {
  const [complete, setComplete] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get("planId");
  const navigate = useNavigate();

  const {
    data: planResponse,
    loading: planResponseLoadingState,
    error: planResponseError,
  } = useQuery<PlanResponse>(PLAN, {
    variables: { id: planId },
  });

  const {
    data: comparePlanResponse,
    loading: comparePlanLoadingState,
    error: comparePlanError,
  } = useQuery<ComparePlanResponse>(COMPARE_PLAN, {
    variables: {
      Id: planId,
    },
  });

  const [
    submitPlan,
    { data, loading: submitPlanLoadingState, error: submitPlanError },
  ] = useMutation(SUBMIT_PLAN_MUTATION);

  async function handleApprove() {
    await submitPlan({
      variables: {
        id: planId,
        action: ReviewAction.APPROVE,
        reason: "",
      },
    });

    setComplete(true);
  }

  async function handleDecline(reason: string) {
    await submitPlan({
      variables: {
        id: planId,
        action: ReviewAction.DENY,
        reason: reason,
      },
    });

    setComplete(true);
  }

  function handleReset() {
    navigate("/");
  }

  if (planResponseLoadingState || comparePlanLoadingState) {
    return <LoadingSection />;
  }

  if (planResponseError) {
    return <Alert severity="error">{planResponseError.message}</Alert>;
  }

  if (comparePlanError) {
    return <Alert severity="error">{comparePlanError.message}</Alert>;
  }

  if (submitPlanError) {
    return <Alert severity="error">{submitPlanError.message}</Alert>;
  }

  // Requesting ComparePlan Api
  // When Responses have 'comparePlan' data include. Load the ControlledAccordions component with the comparePlanResponse
  return (
    <Card
      sx={{
        minWidth: 275,
        padding: 5,
        marginTop: 3,
      }}
    >
      <CardContent>
        <CompareSection
          complete={complete}
          comparePlanResponse={comparePlanResponse ?? { comparePlan: [] }}
          planResponse={
            planResponse ?? {
              plan: {
                id: "",
                type: "",
                status: "",
                review: {
                  action: ReviewAction.REVIEW,
                  reason: "",
                  submitter: {
                    email: "",
                    firstName: "",
                    lastName: "",
                  },
                  reviewer: {
                    email: "",
                    firstName: "",
                    lastName: "",
                  },
                },
              },
            }
          }
          planId={planId ? planId : ""}
          submitPlanLoadingState={submitPlanLoadingState}
          handleApprove={handleApprove}
          handleDecline={handleDecline}
          handleReset={handleReset}
        />
      </CardContent>
    </Card>
  );
}

export default Review;
