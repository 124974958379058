import { useQuery } from "@apollo/client";

import { ValidatePlanResponse } from "../../types";
import { VALIDATE_PLAN } from "../../graphql/queries";
import ErrorSection from "./ErrorSection";
import LoadingSection from "./LoadingSection";
import SuccessSection from "./SuccessSection";

interface ValidationStepProps {
  planId: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function ValidateStep({ planId, setActiveStep }: ValidationStepProps) {
  const { data, loading } = useQuery<ValidatePlanResponse>(VALIDATE_PLAN, {
    variables: { id: planId },
  });

  if (loading) {
    return <LoadingSection />;
  } else if (data && data.validatePlan.errors.length > 0) {
    return (
      <ErrorSection
        errors={data.validatePlan.errors}
        setActiveStep={setActiveStep}
      />
    );
  }
  return <SuccessSection setActiveStep={setActiveStep} />;
}

export default ValidateStep;
