import { KeyValue, ComparePlanResponse, ComparePlan } from "../../types";

export class DataBuilder {
  ModifyRows: object[];
  NewRows: object[];

  constructor() {
    this.ModifyRows = [];
    this.NewRows = [];
  }

  // Each Row of the Data will include ID, difference, and data of the each row
  // ex: { diff: string[], ID:string, dnsName:string, hubCode:string, targetHhp:string, targetYear:string}
  private createRow(
    newPlanCompareItem: KeyValue[],
    oldPlanCompareItem: KeyValue[],
    resultDiff: String[],
    ID: String
  ): object {
    const ComparePlanNew = newPlanCompareItem.map((item: KeyValue) => ({
      [item.field]: item.value,
    }));
    const ComparePlanOld = oldPlanCompareItem.map((item: KeyValue) => ({
      [item.field]: item.value,
    }));
    const row = {
      new: Object.assign({}, ...ComparePlanNew),
      old: Object.assign({}, ...ComparePlanOld),
      diff: resultDiff,
      id: ID,
    };
    return row;
  }

  // Based on the diffType create different row and Push to their collector
  public setRows(comparePlanResponse: ComparePlanResponse) {
    comparePlanResponse.comparePlan.forEach((comparePlan: ComparePlan) => {
      switch (comparePlan.diffType) {
        case "MODIFIED":
          this.ModifyRows.push(
            this.createRow(
              comparePlan.new,
              comparePlan.old,
              comparePlan.diff,
              comparePlan.id
            )
          );
          break;
        case "NEW":
          this.NewRows.push(
            this.createRow(comparePlan.new, comparePlan.old, [], comparePlan.id)
          );
          break;
      }
    });
    return this;
  }

  public build() {
    return this;
  }
}
