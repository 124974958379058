import PlanCreationStepper from "../components/PlanCreationStepper";

function Home() {
  return (
    <div>
      <PlanCreationStepper />
    </div>
  );
}

export default Home;
